import React, {useLayoutEffect, useRef} from "react"
import { Link } from 'gatsby';
import Layout from "../components/layout"
import styled from 'styled-components';
import gsap from 'gsap'

import Logo from "../images/create-logo.png";
import BracketRight from "../images/bracket-right.png";
import BracketLeft from "../images/bracket-left.png";

import Nav from '../components/nav/nav';


import insta from '../images/instagram.png';
import linkedin from '../images/linkedin.png';


import RowWrapper from "../utils/rowWrapper"



const Container = styled.section`
  height: 100vh;
  padding-top: 5rem;
  position: relative;
`
const LogoContainer = styled.div`
  width: 8rem;
  margin: 0 auto;

  img {
    width: 100%;
  }
`

const Heading = styled.h1`
  &, & * {
    text-transform: uppercase;
    font-family: "Museo Sans Rounded 900", sans-serif;
    font-size: 4rem;
    text-align: center;
    /* margin: 10rem 0; */
    cursor: pointer;
  }

  span {
    color: ${props => props.theme.colors.color};
    font: inherit;
  }

  @media ${props => props.theme.breaks.down('sm')} {
    font-size: 2rem;
  }

`

const Center = styled.div`
  width: 80%;
  margin: 20vh auto 0 auto;
  position: relative;

  ul li a {
    color: #fff;
    visibility: hidden;
  }

  @media ${props => props.theme.breaks.down('sm')} {
    width: 80%;
  }

`

const Bracket = styled.div`
    position: absolute;
    /* height: 30rem; */
    width: 7rem;
    transform: translateY(-50%);

    img {
      width: 100%;
    }

  &#bracket-right {
    top: 50%;
    left: -6rem;
  }

  &#bracket-left {
    top: 50%;
    right: -6rem;
  }

  @media ${props => props.theme.breaks.down('sm')} {
    width: 3rem;

    &#bracket-right {
    left: -2.5rem;
  }

    &#bracket-left {
    right: -2.5rem;
  }
  }

`

const Contact = styled.div`
  margin-top: 15rem;

  a.tel {
    display: block;
    font-family: "Museo Sans Rounded 700", sans-serif;
    font-size: 5rem;
    text-align: center;
    margin-bottom: 1rem;

    @media ${props => props.theme.breaks.down('sm')} {
      font-size: 3.5rem;
    }
  }

  a.email {
    display: block;
    font-family: "Museo Sans Rounded 300", sans-serif;
    font-size: 2rem;
    text-align: center;

    @media ${props => props.theme.breaks.down('sm')} {
      font-size: 1.5rem;
    }
  }

  @media ${props => props.theme.breaks.down('sm')} {
    margin-top: 10rem;
  }

`

const Blue = styled.span`
  color: ${props => props.theme.colors.color};
  font: inherit;
`

const Social = styled.div`
  margin-top: 15rem;
  display: flex;
  justify-content: center;

  a {
    display: block;
    width: 2rem;
    margin: .5rem;

    img {
      width: 100%;
    }
  }

  @media ${props => props.theme.breaks.down('sm')} {
    margin-top: 7rem;
  }
`

const NavContainer = styled.div`
  position: fixed;
  top: 5rem;
  right: 5rem;
  z-index: 100;
`


// markup
const ContactPage = () => {

  const cen = useRef()
  const br = useRef()
  const bl = useRef()
  const h1 = useRef()


  useLayoutEffect(() => {
    document.body.style.overflowY = "hidden";

    let lPosStart = window.innerWidth < 600 ? "40%" : "42%";
    let rPosStart = window.innerWidth < 600 ? "60%" : "58%";
    let lPosEnd = window.innerWidth < 600 ? "-2.5rem" : "-6rem";
    let rPosEnd = window.innerWidth < 600 ? "-2.5rem" : "-6rem";

    const tl = gsap.timeline({
      
    });



    tl.addLabel("setup")
    .set(h1.current, {autoAlpha: 0}, "setup")
    .set(br.current, {left: lPosStart, translateX: "-50%", autoAlpha: 0}, "setup")
    .set(bl.current, {left: rPosStart, translateX: "-50%", autoAlpha: 0}, "setup")
    .addLabel("fade-in")
    .to(br.current, {autoAlpha: 1, duration: .6}, "fade-in")
    .to(bl.current, {autoAlpha: 1, duration: .6}, "fade-in")
    .addLabel("spread")
    .to(br.current, {left: lPosEnd, translateX: "0", duration: 1}, "spread+=.2")
    .to(bl.current, {left: "auto", right: rPosEnd, translateX: "0", duration: 1}, "spread+=.2")
    .to(h1.current, {autoAlpha: 1, duration: 1.5})

    return () => {
      document.body.style.overflowY = "auto"
    }

  }, [])

  return (
    <Layout pageTitle="Create A Space - Contact Us" contact={false}>
      <Container>

      <NavContainer>
        <Nav />
      </NavContainer>

        <RowWrapper>
          <LogoContainer>
          <Link to="/"><img src={Logo} alt="Create A Space Logo" /></Link>
          </LogoContainer>
          <Center ref={cen}>
            <Bracket ref={bl} className="bracket" id="bracket-left">
              <img src={BracketLeft} alt="bracket facing left" />
            </Bracket>
             
            <Heading ref={h1}>
              Find Completion. <span>(With Closets.)</span>
            </Heading>

            <Bracket ref={br} className="bracket" id="bracket-right">
              <img src={BracketRight} alt="bracket facing right" />
            </Bracket>
          </Center>

          <Contact>
            <a className="tel" href="tel:845-232-0607">845<Blue>.</Blue>232<Blue>.</Blue>0607</a>
            <a className="email" href="mailto:projects@createaspace.xyz">projects<Blue>@</Blue>createaspace.xyz</a>
          </Contact> 

          <Social>
            <a href="https://instagram.com/createaspace_closets"><img src={insta} alt="instagram link" /></a>
            <a href="https://www.linkedin.com/in/eliezer-dana/"><img src={linkedin} alt="linked in link" /></a>
          </Social>

        </RowWrapper>

      </Container>
    </Layout>
  )
}

export default ContactPage
